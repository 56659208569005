<template>
  <div v-if="!loading"
       class="page-timeline">
    <div class="container-fluid ">
      <EmptyState v-if="!timeline.years?.length"
                  page="timeline" />
      <div v-else
           class="row print-new-page">
        <div class="col-12 col-xxl-8">
          <div class="co-timeline-header">
            <div class="icon timeline" />
            <h2>{{ t('timeline.title') }}</h2>
          </div>

          <div class="co-timeline-years">
            <div v-for="(year, index) in timeline.years"
                 :key="'year-' + index"
                 class="year"
                 :class="{active: year.year === active}"
                 @click="scrollTo(index)">
              {{ year.year }}
            </div>
          </div>

          <div v-for="(year, index) in timeline.years"
               :id="'year'+index"
               :key="'timeline-year-' + index"
               class="co-timeline">
            <div class="co-timeline-year-container">
              <div class="year">
                {{ year.year }}
              </div>
            </div>
            <div class="co-timeline-line" />

            <div v-for="(chunkItem, chunkIndex) in chunk(year.items, 2)"
                 :key="'item-' + chunkIndex">
              <div v-for="(item, itemIndex) in chunkItem"
                   :key="'item-' + itemIndex"
                   class="co-timeline-card-container">
                <div class="co-timeline-card"
                     :class="{'co-timeline-card--even': itemIndex % 2 === 1}">
                  <div class="co-timeline-card__icon icon"
                       :class="item.icon.key"
                       :style="{color: item.icon.color}" />
                  <div class="co-timeline-card__content">
                    <a v-if="item.url"
                       :href="item.url"
                       class="co-timeline-card__content__title"
                       target="_blank">
                      <span>{{ item.title }}</span>
                    </a>
                    <div v-else
                         class="co-timeline-card__content__title">
                      <span>{{ item.title }}</span>
                    </div>
                    <div v-for="(row, rowIndex) in item.rows"
                         :key="'row-' + rowIndex"
                         class="co-timeline-card__content__data">
                      <div class="icon"
                           :class="row.icon.key"
                           :style="{color: row.icon.color}" />
                      <a v-if="row.url"
                         :href="row.url"
                         target="_blank"> {{ row.value }}</a>
                      <span v-else>{{ row.value }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="timeline.company"
               class="co-timeline">
            <div class="co-timeline-card-container">
              <div class="co-timeline-card co-timeline-card--last">
                <div class="co-timeline-card__content">
                  <div class="co-timeline-card__content__data">
                    <div class="w-100">
                      <div class="d-flex justify-content-center w-100 mb-2">
                        <div class="icon company-change-start"
                             :style="{color: 'rgb(33, 193, 0)', fontSize: '3rem'}" />
                      </div>
                      <div class="co-timeline-card__content text-center flex justify-content-center">
                        <div class="co-timeline-card__content__title"
                             style="font-weight: bold">
                          <span>{{ timeline.company.start }}</span>
                        </div>
                        <div class="co-timeline-card__content__title">
                          <span>{{ timeline.company.name }}</span>
                        </div>
                        <div class="co-timeline-card__content__title">
                          <span>{{ timeline.company.address }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="timeline"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t, chunk } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const timeline = ref({} as any);
  const company = ref({
    start: "",
    name: "",
    address: "",
  });
  const active = ref(null);

  function scrollTo(index) {
    document.getElementById("year" + index).scrollIntoView();
    active.value = timeline.value.years[index].year;
  }

  onMounted(async () => {
    await http.fetchWithLoading(timeline, loading, "/api/report/" + route.params.id + "/timeline");
    active.value = timeline.value.years[0].year;
  });
</script>

<style lang="scss" scoped>
.co-timeline-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  & .icon {
    margin-right: 0.5rem;
    color: $color-primary;
    font-weight: 400;
    font-size: 2.6rem;
  }

  & h2 {
    color: $color-font-primary;
    font-weight: 600;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
}

.co-timeline-years {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;

  & .year {
    margin: 1rem 0.5rem 1rem 0;
    padding: 0.25rem 1rem;
    color: $color-font-primary;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: $color-tag-bg;
    border-radius: 100px;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &.active {
      color: white;
      background-color: $color-primary;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.co-timeline {
  position: relative;
  padding: 0 0 3rem;

  &::before {
    content: "";
  }

  & .co-timeline-year-container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 6rem;

    &::after {
      position: absolute;
      left: 50%;
      z-index: -1;
      width: 0.4rem;
      height: 100%;
      background-color: $color-border-secondary;
      transform: translateX(-50%);
      content: "";

      @include media-breakpoint-down(md) {
        left: 2.5rem;
        transform: initial;
      }
    }

    @include media-breakpoint-down(md) {
      justify-content: initial;
    }

    & .year {
      display: inline-flex;
      padding: 0.25rem 1rem;
      color: $color-font-primary;
      font-weight: 500;
      font-size: 1.4rem;
      background-color: $color-tag-bg;
      border-radius: 100px;
      cursor: pointer;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

      &.active {
        color: white;
        background-color: $color-primary;
      }
    }
  }

  & .co-timeline-line {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0.4rem;
    height: 100%;
    background-color: $color-border-secondary;
    transform: translateX(-50%);

    @include media-breakpoint-down(md) {
      left: 2.5rem;
      transform: initial;
    }
  }

  & .co-timeline-card-container {
    margin-top: -3rem;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  & .co-timeline-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(50% - 3.2rem);
    min-height: 8rem;
    padding: 2rem;
    background-color: white;
    border-radius: $border-radius-card;
    box-shadow: $box-shadow-card;

    @include media-breakpoint-down(md) {
      width: calc(100% - 6.5rem);
      margin-top: 3rem;
      margin-left: auto;
    }

    &__icon {
      position: absolute;
      top: 0.5rem;
      right: -4.9rem;
      font-size: 3rem;
      background-color: white;
      border: 2px solid white;
      border-radius: 50%;
      font-size: 3rem !important;

      @include media-breakpoint-down(md) {
        top: 0.25rem;
        right: initial;
        left: -5.5rem;
      }
    }

    &::before {
      position: absolute;
      top: 1rem;
      right: -1rem;
      width: 0;
      height: 0;
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid $color-timeline-arrow;
      content: "";

      @include media-breakpoint-down(md) {
        right: initial;
        left: -1rem;
        border-right: 1rem solid $color-timeline-arrow;
        border-left: initial;
      }
    }

    &--even {
      margin-top: -3rem;
      margin-left: auto;

      @include media-breakpoint-down(md) {
        margin-top: 3rem;
      }

      &::before {
        position: absolute;
        top: 1rem;
        left: -1rem;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-right: 1rem solid $color-timeline-arrow;
        border-bottom: 1rem solid transparent;
        border-left: initial;
        content: "";
      }

      & .co-timeline-card__icon {
        top: 0.25rem;
        right: initial;
        left: -4.9rem;

        @include media-breakpoint-down(md) {
          left: -5.5rem;
        }
      }
    }

    &--last {
      margin-top: 6rem;
      margin-left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-down(md) {
        margin-top: 3rem;
        margin-left: auto;
        transform: none;
      }

      &::before {
        display: none;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      & .co-timeline-card__icon {
        top: 0.25rem;
        right: initial;
        left: -4.9rem;

        @include media-breakpoint-down(md) {
          left: -5.5rem;
        }
      }
    }

    &__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & a {
        text-decoration: none;
      }

      &__title {
        overflow: hidden;
        color: $color-font-primary;
        font-weight: 500;
        font-size: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;

        & span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &__data {
        display: flex;
        overflow: hidden;
        font-size: 1.4rem;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:nth-child(2) {
          margin-top: 1rem;
        }

        & .icon {
          margin-right: 0.5rem;
          font-weight: 600;
          font-size: 1.8rem;
        }

        & a {
          overflow: hidden;
          color: $color-font-primary;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
