<template>
  <div class="container">
    <div class="print-co-header">
      <img src="/assets/shared/credit-online-logo.svg"
           alt="Logo">
      <div style="text-align: right; margin-bottom: 3rem">
        {{ t('reportCreatedAt') }}: {{ date }}
        <div style="font-size: 12px"
             v-html="t('confident')" />
      </div>
    </div>
    

    <Summary v-if="widgets[0].show" />
    <CompanyData v-if="widgets[1].show" :defaultAll="true"/>
    <FinancialReportSummary v-if="widgets[2].show" />
    <div v-if="widgets[3].show && financialReportsFiltered">
      <PageCard :title="t('financialReport.title')"
                icon="reports">
        <template #content>
          <div v-for="finReport in financialReportsFiltered"
               :key="finReport"
               class="co-tab">
            <FinancialReportSheet :data="finReport.balance"
                                  :show-in-thousand="false" />
            <FinancialReportSheet :data="finReport.incomeStatement"
                                  :show-in-thousand="false" />
          </div>
        </template>
      </PageCard>
    </div>
    <CommonRatings v-if="widgets[4].show" />
    <InfoList v-if="widgets[5].show"
              :type="'negative'" />
    <InfoList v-if="widgets[6].show"
              :type="'positive'" />
    <ConnectionList v-if="widgets[7].show" />
    <Timeline v-if="widgets[8].show" />
    <Marketing v-if="widgets[9].show" />
    <Sector v-if="widgets[10].show" />
    <FinalOwner v-if="widgets[11].show" />

    <div class="print-controller">
      <div v-if="!show"
           class="icon widgets blue floating-icon d-none d-sm-flex"
           @click="show = true" />
      <InfoCard v-else>
        <template #header>
          <div class="card__header__title">
            <div class="widgets blue card__header__title__icon icon" />
            <h3 class="card__header__title__text">
              {{ t('print.widgets.title') }}
            </h3>
            <div style="display: flex; justify-content: right; width: 100%" v-if="route.query?.components?.length > 0">
              <a v-if="!downloadStarted"
                 class="download-button"
                 @click="download()">
                <i class="icon export" />
                <span class="ml-2 d-none d-lg-block">{{ t('print.download') }}</span>
              </a>
              <div v-else
                   class="mt-2">
                <span class="spinner-border text-primary" />
                <span class="ml-3 text-primary">{{ t('companyData.buttons.downloading') }}</span>
              </div>
            </div>
            <div class="close-wrapper"
                 @click="show = false">
              <div class="icon company-deleted blue" />
            </div>
          </div>
        </template>
        <template #content>
          <div class="container-fluid">
            <div class="row">
              <div v-for="widget in widgets"
                   :key="widget.key"
                   class="col-6 ">
                <div v-if="(!widget.claim || claims.includes(widget.claim as string)) && (!widget.justInCompany || isCompany)"
                     class="co-checkbox-secondary">
                  <Checkbox v-model="widget.show"
                            :input-id="widget.key"
                            :binary="true" />
                  <label :for="widget.key">{{ t('print.widgets.' + widget.key) }}</label>
                </div>
              </div>
            </div>
            <div v-if="isCompany"
                 class="row mt-4">
              <div class="col-6 ">
                <div class="co-checkbox-secondary">
                  <Checkbox v-model="onlyValid"
                            input-id="onlyValid"
                            :binary="true" />
                  <label for="onlyValid">{{ t('print.onlyValid') }}</label>
                </div>
              </div>
              <div class="col-6 ">
                <div class="co-checkbox-secondary">
                  <Checkbox v-model="onlyLastReport"
                            input-id="onlyLastReport"
                            :binary="true" />
                  <label for="onlyLastReport">{{ t('print.onlyLastReport') }}</label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </InfoCard>
    </div>

    <main style="display: none" />
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import * as ReportWidgets from "./ReportWidgets";
  import { http, t, user, init, chartResize } from "@/logic";
  import Summary from "../report/Summary.vue";
  import CompanyData from "../report/CompanyData.vue";
  import FinancialReportSummary from "../report/FinancialReportSummary.vue";
  import CommonRatings from "../report/CommonRatings.vue";
  import ConnectionList from "../report/ConnectionList.vue";
  import Timeline from "../report/Timeline.vue";
  import Marketing from "../report/Marketing.vue";
  import InfoList from "../report/InfoList.vue";
  import Sector from "../report/Sector.vue";
  import FinalOwner from "../report/FinalOwner.vue";

  init();

  const route = useRoute();
  const router = useRouter();
  const show = ref(true);
  const onlyValid = ref(true);
  const onlyLastReport = ref(true);
  const downloadStarted = ref(false);
  const companyName = ref("");
  const date = ref("");
  const isCompany = ref(true);
  const financialReport = ref({
    years: [],
    balance: {},
    incomeStatement: {}
  });
  const financialReportsFiltered = ref(null);
  const widgets = reactive(ReportWidgets.default);
  const claims = computed(() => {
    return (user.claims || "").split(",");
  });  
  
  async function getFinancials() {
    const years = onlyLastReport.value 
      ? financialReport.value.years.reverse().slice(-1)
      : financialReport.value.years.reverse().slice(-5);
    financialReportsFiltered.value = [];

    years.forEach(async year => {
      const report = await getFinancialReport(year);
      financialReportsFiltered.value.push(report);
    });
  }

  async function load() {
    if (widgets[3].show) {
      await http.fetch(financialReport, `/api/report/${route.params.id}/financial-report`);		
      await getFinancials();
    }
  }

  async function getFinancialReport(year: number) {
    return await http.get(`/api/report/${route.params.id}/financial-report?year=${year}`);
  }

  async function getCompanyName() {
    companyName.value = (await http.get("/api/report/" + route.params.id + "/summary")).details.name;
  }

  async function updateQuery() {
    let components = [];
    widgets.forEach((widget) => {
      if (widget.show) {
        components.push(widget.key);
      }
    });
    var query = "components=" + components.join() + "&onlyLastReport=" + onlyLastReport.value +
      "&onlyValid=" + onlyValid.value;

    if (new URLSearchParams(route.query.toString()).toString() != query) {
      router.replace(route.path + "?" + query).catch((err) => { });

      await load();
    }        
  }

  async function download() {
    await http.blob(`/api/report/print/${route.params.id}/${encodeURIComponent(route.fullPath)}`, downloadStarted);
  }

  onMounted(async () => {
    let components = [];

    if (route.query && route.query.onlyLastReport != undefined) {
      onlyLastReport.value = route.query.onlyLastReport == "true";
    }

    if (route.query && route.query.onlyValid != undefined) {
      onlyValid.value = route.query.onlyValid == "true";
    }

    if (route.query && route.query.components) {
      components = (route.query.components as string).split(",");
      widgets.forEach((widget) => {
        widget.show = components.includes(widget.key);
      });
    } else {
      widgets.forEach((widget) => {
        if (widget.show) {
          components.push(widget.key);
        }
      });
    }    

    isCompany.value = route.path.search("/(SE|NP|BI|EI|TE)") == -1;
    await updateQuery();
    await getCompanyName();
    date.value = new Date().toLocaleString("hu");
    await load();
  });

  watch(widgets, updateQuery, { deep: true });
  watch(onlyLastReport, updateQuery);
  watch(onlyValid, updateQuery);

  window.addEventListener('beforeprint', () => {
    chartResize();
  });
</script>

<style scoped lang="scss">
:deep(.no-print) {
  display: none;
}

.print-co-header {    
  display: flex;
  justify-content: space-between;

  & img {
    height: 6rem;
  }
}

:deep() {
  & .widget-controller {
    display: none !important;
  }

  &.alternativeCard {
    display: none !important;
  }
}

:deep() {
  .col-xxl-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

:deep() {
  & .widget-controller,
  & .map-marker,
  & .copy,
  & .inner-wrapper-sticky,
  & .button,
  & .sort-up,
  & .sort-down,
  & .co-checkbox-primary {
    display: none !important;
  }

  & .line--card-separator {
    margin: 0.5rem 0 !important;
  }

  & .cc-title {
    font-size: 3rem !important;
  }

  & .cc-address a {
    display: none !important;
  }

  & table a {
    color: $color-font-primary !important;
    text-decoration: none !important;
  }

  & table td {
    padding: 0.25rem 0.5rem !important;
  }

  & .card {
    margin: 0 !important;
    padding: 2rem !important;

    @media print {
      break-inside: avoid !important;
      page-break-inside: avoid !important;
      border: 1px solid $color-border-secondary !important;
    }

    &.alternativeCard {
      display: none !important;
    }

    &:first-child {
      margin-bottom: 1rem !important;
    }

    &__header {
      margin-bottom: 0 !important;

      &__graph,
      &__dropdown {
        display: none !important;
      }

      &__title {
        margin-bottom: 0rem !important;
      }

      &__info {
        display: none !important;
      }
    }

    &__content {
      display: block !important;

      & td {
        padding: 0 !important;
      }

      & .cardCompanyDetails {
        & .title {
          min-width: 22rem !important;

          @media print {
            color: black !important;
          }
        }

        & .badge {
          border: none !important;

          @media print {
            color: black !important;
          }
        }
      }
    }
  }

  & .print-percent-50 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  & .print-percent-100 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  & .indicator {
    margin: 0 !important;
    margin-bottom: 1rem !important;
    border: 1px solid $color-border-secondary !important;

    &__info {
      display: none !important;
    }
  }

  & .indicator-table {
    &__content {
      &__data {
        color: $color-font-primary !important;
        -webkit-text-stroke: initial !important;

        & span {
          color: $color-font-primary !important;
        }
      }
    }
  }

  & #ratingDetails {
    margin-bottom: 6rem !important;
  }

  & .chartjs-render-monitor {
    width: 100% !important;
  }

  & .cardContacts {
    margin-bottom: 1rem !important;

    & a {
      text-decoration: none !important;
    }
  }

  & .flexTable {
    margin-top: 3rem !important;
    margin-bottom: 4rem !important;

    &__title {
      margin-bottom: 0 !important;
      border-bottom: none !important;
    }

    &__content {
      border: none !important;

      &__data {
        padding: 0 !important;
      }
    }
  }

  & .year-container {
    display: none !important;
  }

  & .interactive-table {
    &__header {
      @media print {
        color: black !important;
      }
    }

    &__table {
      &__row {
        &__data {
          & a {
            color: $color-font-primary !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }

  & .tab-container {
    box-shadow: none !important;
  }

  & .collapse {
    display: block !important;

    & .title {
      &::after {
        display: none !important;
      }
    }
  }

  & .tabs {
    & .nav-tabs {
      display: none !important;
    }

    & ul,
    & .nav-tabs {
      border-bottom: none !important;
    }
  }

  & .co-collapse {
    padding-top: 3rem !important;

    & .title {
      color: $color-font-primary !important;

      &::after {
        display: none !important;
      }
    }
  }

  & .chart-collapse {
    padding: 0 !important;
    page-break-inside: avoid !important;
    border-top: none !important;
    break-inside: avoid !important;
  }

  & .print-covid {
    position: relative !important;
    margin-top: 5rem !important;
    margin-bottom: 3rem !important;
  }

  & .print-taxTable {
    position: relative !important;
    margin-bottom: 3rem !important;
  }

  & .print-closedCompaniesTable {
    position: relative !important;
    margin-bottom: 5rem !important;
  }

  & .page-financial-report-summary {
    position: relative !important;
  }

  & .page-financial-report {
    position: relative !important;
  }

  & .page-common-ratings {
    position: relative !important;
  }

  & .negative-collapse__content__details {
    display: none !important;
  }

  & .co-timeline-years {
    display: none !important;
  }

  & .connection-list {
    .row {
      // break-inside: avoid !important;
      // page-break-inside: avoid !important;
    }
  }

  & .co-timeline-card {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    border: 1px solid $color-border-secondary !important;

    & * {
      justify-content: flex-end !important;
    }

    &__content {
      &__title {
        display: block !important;
        text-align: right !important;
      }
    }

    &--even,
    &--last {
      & * {
        justify-content: flex-start !important;
      }

      & .co-timeline-card {
        &__content {
          &__title {
            text-align: left !important;
          }
        }
      }
    }
  }

  & .page-timeline {
    position: relative !important;
  }

  & .page-marketing {
    position: relative !important;
  }

  & .multiselect {
    margin-top: 1rem !important;
  }

  & .page-sector {
    position: relative !important;
  }

  & .page-final-owner {
    break-after: avoid !important;
  }
}

.print-controller {
  position: fixed;
  right: 7rem;
  bottom: 1rem;
  z-index: $z-index-widget-controller;

  @media print {
    display: none !important;
  }

  & .floating-icon {
    width: 5rem;
    font-size: 2.4rem;
    background-color: white;
    border: 1px solid $color-blue;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;

    &.blue {
      color: $color-blue;
      text-align: center;
    }

    &::before {
      margin: auto;
    }
  }

  .card {
    max-width: 60rem;

    @include media-breakpoint-down(md) {
      max-width: 35rem;
    }

    & .icon {
      width: initial;
      height: initial;
    }

    & .close-wrapper {
      position: absolute;
      top: 2.4rem;
      right: 1rem;
    }

    &__header {
      &__title {
        margin-bottom: 2rem;
      }
    }
  }
}

.company-deleted {
  font-size: 3rem;
}

:deep() {
  & .print-row {
    display: block !important;
  }

  & .print-percent-50 {
    display: block !important;
    float: left !important;
    page-break-inside: avoid !important;
  }

  & .print-new-page {
    display: block !important;
  }
}

.backToTopButton {
  display: none !important;
}

.download-button {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: right;
  margin: 0 10px 0 10px;
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  background-color: $color-primary;
  border: 1px solid #f3792b;
  border-radius: 100px;
  cursor: pointer;
}

.download-button:hover {
  text-decoration: none;
}

.card:deep(.card__header) {
  display: block;
  width: 100%;
  padding-right: 20px;
}

.page-financial-report {
  div {
    display: block !important;
  }
}
</style>
