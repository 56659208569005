<template>
  <div>
    <div class="background-image" />
    <Card class="page-profile-edit p-4">
      <template #header>
        <i class="pi icon profile-user" />
        <span class="title">{{ t('profileEdit.title') }}</span>   
      </template>
      <template #content>
        <div class="edit">
          <div class="container fluid">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileEdit.userDetails') }}
                  </h3>
                  <hr class="line">
                  <div class="co-input-marketing">
                    <label for="">{{ t('profileEdit.form.name') }}</label>
                    <InputText v-model="data.name" />
                  </div>
                  <div class="co-input-marketing">
                    <label for="">{{ t('profileEdit.form.mobile') }}</label>
                    <InputText v-model="data.phone" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileEdit.changePassword') }}
                  </h3>
                  <hr class="line">
                  <div class="co-input-marketing">
                    <label for="">{{ t('profileEdit.form.newPassword') }}</label>
                    <InputText v-model="data.password"
                               :placeholder="t('profileEdit.min8Char')" />
                  </div>
                  <div class="co-input-marketing">
                    <label for="">{{ t('profileEdit.form.newPasswordConfirm') }}</label>
                    <InputText v-model="data.passwordConfirmation"
                               :placeholder="t('profileEdit.mustBeSame')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <Button :label="t('profileEdit.form.save')"
                      class="mt-4"
                      @click="store" />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { http, t, toast, user, init } from "@/logic";

  init();

  const data = ref({
    name: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    credit: 0
  });

  async function load() {
    await http.fetch(data, "/api/profile");
  }

  async function store() {
    await user.fetchMe();
    toast((await http.post("/api/profile", data.value)).message, "success");
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.page-profile-edit {
  position: relative;
  left: 50%;
  width: 100%;
  max-width: 110rem;
  margin-top: 25vh;
  transform: translateX(-50%);

  & .edit {
    & .title {
      margin: 3rem 0 1rem;
      color: $color-font-quaternary;
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;

      &:first-child {
        margin-top: 0;
      }
    }

    & .co-input-marketing label {
      min-width: 15rem !important;
      text-align: left !important;
    }
  }
}
</style>
