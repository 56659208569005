<template>
  <div class="companyDetails">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.companyName') }}</label>
          <InputText v-model="model.companyName" id="company-name"
                     :placeholder="t('marketing.companyDetails.inputs.companyName')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.taxNumber') }}</label>
          <InputText v-model="model.taxNumber" id="tax-number"
                     :placeholder="t('marketing.companyDetails.inputs.taxNumber')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.companyNumber') }}</label>
          <InputText v-model="model.companyNumber" id="company-number"
                     :placeholder="t('marketing.companyDetails.inputs.companyNumber')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.bankAccountNumber') }}</label>
          <InputText v-model="model.bankAccountNumber" id="bank-account-number"
                     :placeholder="t('marketing.companyDetails.inputs.bankAccountNumber')" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="co-input-marketing multiple">
        <label for="">{{ t('marketing.companyDetails.inputs.foundation') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6  min-max">
              <Calendar v-model="model.foundation.min" dateFormat="yy.mm.dd" id="foundation-min"
                      :placeholder="t('marketing.companyDetails.inputs.min')"  />
            </div>
            <div class="col-6  min-max">
              <Calendar v-model="model.foundation.max" dateFormat="yy.mm.dd" id="foundation-max"
                      :placeholder="t('marketing.companyDetails.inputs.max')"  />
            </div>
          </div>
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.employees') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6  min-max">
              <InputText v-model="model.employees.min" id="employees-min"
                         :placeholder="t('marketing.companyDetails.inputs.min')" />
            </div>
            <div class="col-6  min-max">
              <InputText v-model="model.employees.max" id="employees-max"
                         :placeholder="t('marketing.companyDetails.inputs.max')" />
            </div>
          </div>
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.type') }}</label>
          <MultiSelect v-model="model.type" id="type"
                       :options="prefill.types"  
                       option-label="value" 
                       :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.status') }}</label>
          <MultiSelect v-model="model.status" id="status"
                       :options="prefill.statuses" 
                       option-label="value"
                       :placeholder="t('multiselect.placeholder')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Calendar from "primevue/calendar";
  import { t } from "@/logic";
  
  const model = defineModel() as any;

  defineProps(["prefill"]);
</script>

<style scoped lang="scss">
.m-initial {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>
