<template>
  <Card v-if="open" class="p-4">
    <template #header>
      <i class="pi pi-download" />
      <span class="title">{{ t('marketingResults.export') }}</span>
    </template>
    <template #content>
      <div v-if="!started"
           class="settings">
        <div class="container-fluid">
          <p class="mb-4">
            {{ t('marketingExport.exportDescription') }}
          </p>
          <h3 class="">
            {{ t('marketingExport.exportData') }}
          </h3>
          <div class="mt-3 row row-cols-1 row-cols-md-3 g-2">
            <div v-for="item of items"
                 :key="item"
                 class="col">
              <Checkbox v-model="data[item]"
                        :input-id="item"
                        :value="item"
                        :binary="true" />
              <label :for="item">{{ t('marketingExport.' + item) }}</label>
            </div>
          </div>
          <div class="mt-5 row row-cols-1 row-cols-md-3 g-2">
            <div>
              <Checkbox v-model="data.onlyWithPhoneNumber"
                        input-id="onlyWithPhoneNumber"
                        :binary="true" />
              <label for="onlyWithPhoneNumber">{{ t('marketingExport.onlyWithPhoneNumber') }}</label>
            </div>
            <div>
              <Checkbox v-model="data.onlyWithEmail"
                        input-id="onlyWithEmail"
                        :binary="true" />
              <label for="onlyWithEmail">{{ t('marketingExport.onlyWithEmail') }}</label>
            </div>
            <div>
              <Checkbox v-model="data.onlyWithWebsite"
                        input-id="onlyWithWebsite"
                        :binary="true" />
              <label for="onlyWithWebsite">{{ t('marketingExport.onlyWithWebsite') }}</label>
            </div>
          </div>
          <div class="mt-5 row row-cols-1 row-cols-md-3 g-2">
            <div>
              <Checkbox v-model="data.addressSplit"
                        input-id="addressSplit"
                        :binary="true" />
              <label for="addressSplit">{{ t('marketingExport.addressSplit') }}</label>
            </div>
            <div>
              <Checkbox v-model="data.mainActivityDescription"
                        input-id="mainActivityDescription"
                        :binary="true" />
              <label for="mainActivityDescription">{{ t('marketingExport.mainActivityDescription') }}</label>
            </div>
          </div>
          <div class="row my-5">
            <div class="col">
              <div v-if="prefill.count"
                   class="font-weight-bold">
                {{ prefill.count }}
              </div>
              <div v-if="prefill.price"
                   class="font-weight-bold">
                {{ t('marketingExport.price') }} {{ prefill.price }}
              </div>
            </div>
          </div>
          <div v-if="prefill.price"
               class="d-flex justify-content-center">
            <div class="button button--primary"
                 @click="startExport">
              {{ t('marketingExport.start') }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p style="text-align: center">
          {{ t('marketingExport.started') }}
        </p>
      </div>
      <ConfirmDialog />
    </template>
  </Card>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t, route, init } from "@/logic";
  import ConfirmDialog from "primevue/confirmdialog";
  import { useConfirm } from "primevue/useconfirm";

  const items = [
    "taxNumber",
    "companyRegistrationNumber",
    "companyName",  
    "companyAddress",  
    "phone",  
    "email",  
    "website",
    "entitledToSign",
    "owners",  
    "companyStatus",  
    "mainActivity",  
    "employees",  
    "yearOfFoundation",  
    "netSales",
    "domesticSales",
    "exportSales",
    "profitAfterTax", 
    "county",
    "bankAccountNumber",
    "certification",
    "lendingProposal",
    "profitBeforeTax",
    "operatingProfit",
    "firmSite"
  ];

  const props = defineProps(["open"]);

  defineExpose({ setPrice });

  init();

  const confirm = useConfirm();
  const started = ref(false);
  const prefill = ref({
    phoneCount: 0,
    emailCount: 0,
    websiteCount: 0,
    count: 0,
    price: 0,
  } as any);
  const data = ref({
    onlyWithPhoneNumber: false,
    onlyWithEmail: false,
    onlyWithWebsite: false,
    mainActivityDescription: false,
    addressSplit: false,
    taxNumber: true,
    companyRegistrationNumber: true,
    companyName: true,
    companyAddress: true,
    phone: false,
    email: false,
    website: false,
    entitledToSign: false,
    owners: false,
    companyStatus: false,
    mainActivity: false,
    employees: false,
    yearOfFoundation: false,
    netSales: false,
    domesticSales: false,
    exportSales: false,
    profitAfterTax: false,
    county: false,
    bankAccountNumber: false,
    certification: false,
    lendingProposal: false,
    profitBeforeTax: false,
    operatingProfit: false,
  } as any);

  async function load() {
    const res = await http.post(`/api/marketing/${route.params.id}/export/calc`, {
      selected: data.value,
    });
    prefill.value = res;
  }

  async function onToggle(val) {
    if (val) {
      load();
    }
  }

  function setPrice(price) {
    prefill.value.price = price;
  }

  async function startExport() {
    confirm.require({
      position: "center",
      header: t("marketingExport.confirmTitle"),
      message: t("marketingExport.confirm"),
      acceptLabel: t("marketingExport.okTitle"),
      rejectLabel: t("marketingExport.cancelTitle"),
      accept: async () => {
        await http.post(`/api/marketing/${route.params.id}/export/run`, {
          selected: data.value,
        });
        started.value = true;
      }
    });
  }

  watch(() => data.value, load, { deep: true });
</script>

<style scoped lang="scss">
.icon {
  color: $color-primary;
}

.co-input-marketing {
  label {
    min-width: initial !important;
    margin-right: 1rem !important;
  }
}
</style>
