<template>
  <div v-if="!loading"
       class="connection-list">
    <div class="container-fluid ">
      <div class="row print-new-page">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('connectionList.title')"
                    icon="switch">
            <template #controls>
              <div class="d-md-flex flex-md-row d-none">
                <Button v-if="openAll === false"
                        size="small"
                        :label="t('negativeInfos.buttons.openAll')"
                        icon="pi pi-angle-down"
                        icon-pos="right"
                        @click="onOpenAll" />
                <Button v-else
                        size="small"
                        :label="t('negativeInfos.buttons.closeAll')"
                        icon="pi pi-angle-up"
                        icon-pos="right"
                        @click="onOpenAll" />
              </div>
            </template>
            <template #content>
              <EmptyState v-if="!list.groups"
                          page="connectionList" />
              <template v-else>
                <div class="connection-list-inner">
                  <div v-if="list.header"
                       class="type-container d-flex flex-column flex-lg-row flex-wrap">
                    <div class="type flex-nowrap mt-2">
                      <div class="tag">
                        {{ list.header.interest.count }} {{ t("connectionList.interest.title") }}
                      </div>
                      <div class="text active">
                        {{ list.header.interest.active }} {{ t("connectionList.active") }}
                      </div>,
                      <div class="text inactive ml-1">
                        {{ list.header.interest.inactive }} {{ t("connectionList.inactive") }}
                      </div>
                    </div>
                    <div class="type flex-nowrap mt-2">
                      <div class="tag">
                        {{ list.header.owner.count }} {{ t("connectionList.owner.title") }}
                      </div>
                      <div class="text active">
                        {{ list.header.owner.active }} {{ t("connectionList.active") }}
                      </div>,
                      <div class="text inactive ml-1">
                        {{ list.header.owner.inactive }} {{ t("connectionList.inactive") }}
                      </div>
                    </div>
                    <div class="type flex-nowrap mt-2">
                      <div class="tag">
                        {{ list.header.affiliatedCompany.count }} {{ t("connectionList.affiliatedCompany.title") }}
                      </div>
                      <div class="text active">
                        {{ list.header.affiliatedCompany.active }} {{ t("connectionList.active") }}
                      </div>,
                      <div class="text inactive ml-1">
                        {{ list.header.affiliatedCompany.inactive }} {{ t("connectionList.inactive") }}
                      </div>
                    </div>
                  </div>
                  <div class="groups mt-5 pb-5">
                    <ConnectionListGroup v-for="(group, index) in list.groups" 
                                         :key="'group-' + index" 
                                         :group="group" 
                                         :hide-inactives="user.onlyValid" />
                  </div>
                </div>
              </template>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <InfoCard>
            <template #content>
              <div>
                <div>
                  <div class="mt-2">
                    {{ t('connectionList.description') }}
                  </div>
                </div>
                <div class="notations">
                  <div class="notation-column">
                    <div class="notation-row">
                      <img class="icon"
                           src="/assets/icons/connection-list/working.svg"
                           alt="Icon">
                      <p class="text">
                        {{ t('connections.card.notations.working') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <img class="icon"
                           src="/assets/icons/connection-list/under-procedure.svg"
                           alt="Icon">
                      <p class="text">
                        {{ t('connections.card.notations.underProcedure') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <img class="icon"
                           src="/assets/icons/connection-list/ceased.svg"
                           alt="Icon">
                      <p class="text">
                        {{ t('connections.card.notations.ceased') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <div class="exception-icon red">
                        JE
                      </div>
                      <p class="text">
                        {{ t('connections.card.notations.predecessedCompany') }}
                      </p>
                    </div>
                  </div>
                  <div class="notation-column">
                    <div class="notation-row">
                      <img class="icon"
                           src="/assets/icons/connection-list/connection-network.svg"
                           alt="Icon">
                      <p class="text">
                        {{ t('connections.card.notations.connectionNetwork') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <img class="icon"
                           src="/assets/icons/connection-list/headquarter.svg"
                           alt="Icon">
                      <p class="text">
                        {{ t('connections.card.notations.headquarter') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <div class="exception-icon gray">
                        V
                      </div>
                      <p class="text">
                        {{ t('connections.card.notations.leader') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <div class="exception-icon gray">
                        T
                      </div>
                      <p class="text">
                        {{ t('connections.card.notations.owner') }}
                      </p>
                    </div>
                    <div class="notation-row">
                      <div class="exception-icon gray">
                        VT
                      </div>
                      <p class="text">
                        {{ t('connections.card.notations.leaderOwner') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </InfoCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted } from "vue";
  import { http, t, init, route, user } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  init();

  const openAllBus = useEventBus<boolean>("connectionList/openAll");
  const showOnlyValidEventBus = useEventBus<boolean>("showOnlyValid");
  const loading = ref(false);
  const openAll = ref(true);
  const hideInactives = ref(false);
  const list = ref({} as any);

  async function onOpenAll() {
    openAll.value = !openAll.value;
    openAllBus.emit(openAll.value);
  }

  onMounted(async () => {
    const url = `/api/report/${route.params.id}/connection-list` +
      (route.params.section ? `/${route.params.section}/${route.params.article}` : "");
    await http.fetchWithLoading(list, loading, url);
    showOnlyValidEventBus.emit(true);
  });

  onUnmounted(async () => {
    if (!route.path.includes("print")) {
      showOnlyValidEventBus.emit(false);
    }
  });
</script>

<style scoped lang="scss">
.connection-list-inner {
  margin: 2rem !important;
}

.type {
  display: flex !important;
  align-items: center !important;
  margin-right: 1rem !important;

  @include media-breakpoint-down(lg) {
    margin: 1rem 1rem 0 0 !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  & .tag {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 0.5rem !important;
    padding: 0.25rem 1rem !important;
    color: $color-tag-text !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    background-color: $color-tag-bg !important;
    border-radius: 100px !important;

    &:last-child {
      margin-right: 0 !important;
    }

    @include media-breakpoint-down(lg) {
      min-width: 18rem;
    }
  }

  & .text {
    color: $color-font-primary !important;
    font-weight: 500 !important;
    font-size: 1.4rem !important;

    &.active {
      color: #21c100 !important;
    }

    &.inactive {
      color: #727780 !important;
    }
  }
}

.type,
.tag,
.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notations {
  display: flex;

  & .notation-column {
    min-width: 20rem;

    @include media-breakpoint-down(md) {
      min-width: 17.5rem;
    }

    & .notation-row {
      display: flex;
      align-items: center;
      margin: 1rem 0;

      & .icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }

      & .exception-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
        font-family: $font-primary;
        border-radius: 50%;

        &.gray {
          background-color: $color-gray;
        }

        &.orange {
          background-color: $color-orange;
        }

        &.dark-orange {
          background-color: $color-orange-dark;
        }

        &.red {
          background-color: $color-red;
        }

        &.blue {
          background-color: $color-blue;
        }
      }

      & .text {
        color: $color-font-primary;
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }
}
</style>
