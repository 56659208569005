<template>
  <div class="co-input-marketing multiple">
    <label>{{ t(props.label) }}</label>
    <div class="row m-initial w-100">
      <div class="col-6  min-max">
        <InputText v-model="model.min"
                   :placeholder="t(props.placeholder + '.min')" />
      </div>
      <div class="col-6  min-max">
        <InputText v-model="model.max"
                   :placeholder="t(props.placeholder + '.max')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, defineProps, withDefaults, defineModel } from "vue";
  import { t } from "@/logic";

  const model = defineModel<{
    min: string,
    max: string,
  }>();
  var props = withDefaults(defineProps<{
    placeholder: string,
    label: string,    
  }>(), {
    
  });
</script>
