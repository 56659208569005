<template>
  <div class="page-login"
       :style="{backgroundImage: 'url(' + background + ')'}">
    <nav>
      <a href="https://www.creditonline.hu">
        <img class="logo"
             src="/assets/shared/credit-online-logo.svg"
             alt="Logo">
      </a>
      <div class="language-selector">
        <div v-if="currentLang !== 'hu'"
             class="language"
             @click="changeLocale('hu')">
          <span class="fi fi-hu"></span>
          <span style="margin-left: 0.5rem">{{ t('languages.hu') }}</span>
        </div>
        <div v-if="currentLang !== 'en'"
             class="language"
             @click="changeLocale('en')">
          <span class="fi fi-gb"></span>
          <span style="margin-left: 0.5rem">{{ t('languages.en') }}</span>
        </div>
        <div v-if="currentLang !== 'de'"
             class="language"
             @click="changeLocale('de')">
          <span class="fi fi-de"></span>
          <span style="margin-left: 0.5rem">{{ t('languages.de') }}</span>
        </div>
      </div>
    </nav>
    <div v-if="props.type == 'login'"
         class="form-section">
      <div class="login-form ">
        <Card v-if="page === 'login'" class="p-4 my-2">
          <template #header>
            <i class="pi pi-arrow-circle-right" />
            <span class="title">{{ t('login.title') }}</span>
          </template>
          <template #content>
            <form>
              <div class="co-input-login">
                <label>{{ t('login.user') }}</label>
                <InputText v-model="username"
                           autofocus
                           @keyup.enter="onEnterKey" />
              </div>
              <div class="co-input-login">
                <label>{{ t('login.password') }}</label>
                <InputText v-model="password"
                           type="password"
                           @keyup.enter="onEnterKey" />
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="link"
                     @click="page = 'forgotPassword'">
                  {{ t('login.passwordRecovery') }}
                </div>
                <div class="button button--secondary"
                     @click="login">
                  {{ t('login.signIn') }}
                </div>
              </div>
            </form>
          </template>
        </Card>

        <Card v-if="page === 'forgotPassword'" class="p-4 my-2">
          <template #header>
            <i class="pi pi-arrow-circle-right" />
            <span class="title">{{ t('login.forgot-password') }}</span>
          </template>
          <template #content>
            <form>
              <div class="co-input-login">
                <label>{{ t('login.email') }}</label>
                <InputText v-model="forgotPasswordEmail" />
              </div>
              <div>
                <div class="button button--secondary"
                     @click="sendForgotPassword">
                  {{ t('login.resetPassword') }}
                </div>
              </div>
            </form>
          </template>
        </Card>

        <Card v-if="page === 'message'" class="p-4 my-2">
          <template #content>
            <form>
              <div class="mb-4 text-center">
                {{ message }}
              </div>
              <div>
                <div class="button button--secondary"
                     @click="page = 'login'">
                  {{ t('login.signIn') }}
                </div>
              </div>
            </form>
          </template>
        </Card>
      </div>
      <Card class="register-form p-4 my-2">
        <template #header>
          <i class="pi pi-user" />
          <span class="title">{{ t('register.title') }}</span>
        </template>
        <template #content>
          <p class="register-text">
            {{ t('register.description') }}
          </p>
          <a class="button button--primary"
             href="https://www.creditonline.hu/landing/">{{ t('register.button') }}</a>
        </template>
      </Card>
    </div>
    <div v-if="props.type == 'demo'"
         class="form-section">
      <div class="login-form">
        <Card v-if="page === 'login'" class="p-4 my-2">
          <template #header>
            <i class="pi pi-arrow-circle-right" />
            <span class="title">{{ t('demo-login.title') }}</span>
          </template>
          <template #content>
            <form>
              <div class="terms">
                <div v-html="t('demo-login.terms')" />
                <div class="row checkbox"
                     style="justify-content: center">
                  <div class="checkbox">
                    <input id="terms"
                           v-model="accepted"
                           type="checkbox"
                           name="accept"
                           required>
                    <label for="terms"
                           style="margin-left: 10px"><span> {{ t('demo-login.accept') }} </span></label>
                  </div>
                </div>
              </div><br>
              <div class="d-flex align-items-center"
                   style="justify-content: center">
                <div class="button button--secondary"
                     @click="login">
                  {{ $t('login.signIn') }}
                </div>
              </div>
            </form>
          </template>
        </Card>

        <Card v-if="page === 'message'" class="p-4 my-2">
          <template #content>
            <form>
              <div class="mb-4 text-center">
                {{ message }}
              </div>
              <div>
                <div class="button button--secondary"
                     @click="page = 'login'">
                  {{ t('login.signIn') }}
                </div>
              </div>
            </form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t, user, init } from "@/logic";

  init();

  const props = defineProps(["type"]);

  const route = useRoute();
  const router = useRouter();
  const currentLang = ref(user.locale);
  const forgotPasswordEmail = ref("");
  const username = ref("");
  const password = ref("");
  const page = ref("login");
  const message = ref("");
  const background = computed(() => {
    const rand = Math.floor(Math.random() * (5 - 1)) + 1;

    return `/assets/login/background-${rand}.jpg`;
  });
  const accepted = ref(false);

  function changeLocale(locale) {
    user.locale = locale;
    location.reload();
  }

  function setMessage(msg) {
    message.value = msg;
    page.value = "message";
  }

  async function sendForgotPassword() {
    const data = await http.post("/api/auth/recover-password", {
      email: forgotPasswordEmail.value,
    });
    setMessage(data.message);
  }

  async function login() {
    if (props.type == "login") {
      const data = await http.post("/api/auth/login", {
        username: username.value,
        password: password.value,
      });

      if (!data.message) {
        await user.login(data.token);
        await redirectToHome();
      } else {
        setMessage(data.message);
      }
    } else {
      if (!accepted.value) {
        setMessage("Kérjük, szíveskedjen a feltételeket elfogadni!");
      } else {
        const data = await http.post("/api/auth/login", {
          username: route.params.email,
          password: route.params.password,
        });

        if (!data.message) {
          await user.login(data.token);
          await redirectToHome();
        } else {
          setMessage(data.message);
        }
      }
    }
  }

  function onEnterKey() {
    login();
  }

  async function redirectToHome() {
    await user.fetchMe();
    if (route.query.redirect) {
      router.push(route.query.redirect as string);
    } else {
      router.push("/");
    }
  }
</script>

<style lang="scss" scoped>
.co-input-login {
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
  margin: 1.25rem 0 !important;
}

.co-input-login label {
  margin-bottom: 1rem !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  text-align: right !important;
  text-transform: uppercase !important;
}

.co-input-login input {
  display: block;
  width: 100%;
  height: initial !important;
  padding: 0.5rem !important;
  color: #000 !important;
  font-size: 1.4rem !important;
  border: 2px solid #CDCED2 !important;
  border-radius: 0 !important;
  transition: border .2s ease-in-out !important;
}

.page-login {
  width: 100vw;
  min-height: 100vh;
  padding: 0 2rem;
  background-size: cover;

  & .marketing-card {
    margin-top: 3rem;
  }

  & nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem;
    background-color: white;
    box-shadow: $box-shadow-login;

    & .logo {
      width: 19.24rem;
      height: 3.5rem;
    }

    & .back-to-website {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    & .language-selector {
      display: flex;

      & .language {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          & span {
            color: $color-primary;
          }
        }

        & img {
          height: 1.5rem;
          margin-right: 0.5rem;
        }

        & span {
          color: $color-font-primary;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 1;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }
  }

  & :deep(.content) {
    padding: 0.3rem !important;
  }

  & .form-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 17.5vh;
    padding-bottom: 2rem;

    & .login-form {
      width: 100%;
      max-width: 40rem;

      & form {
        width: 100%;
        background-color: white;

        & .link {
          color: $color-blue;
          font-size: 1.4rem;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    & .register-form {
      width: 100%;
      max-width: 40rem;

      & .register-text {
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
      }

      & .button {
        display: block;
        margin: 2rem;
        text-align: center;
      }
    }
  }
}
</style>
