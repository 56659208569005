<template>
  <SearchPage icon="international" :title="t('international.title')">
    <template #content>
      <div class="page-international pt-5">
        <div class="container-fluid">
          <div class="d-flex flex-column gap-5">
            <Card class="p-4">
              <template #header>
                <i class="pi pi-globe" />
                <span class="title">{{ t('international.onlineTitle') }}</span>
              </template>
              <template #content>
                <p class="subtitle">
                  {{ $t('international.chooseCountry') }}
                </p>
                <div class="container">
                  <div class="row row-cols-1 row-cols-md-5 g-4">
                    <div v-for="country in data.online" :key="country.key" >
                      <RadioButton class="align-middle" v-model="onlineCountry" :input-id="country.key" 
                        :value="country.key" />
                      <label :for="country.key" class="ml-1 align-middle">
                        <span :class="'fi fi-' + country.key"></span>
                        {{ country.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <p class="subtitle">
                  {{ $t('international.inputs.companyNameLabel') }}
                </p>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="co-input-marketing">
                      <label for="">{{ $t('international.inputs.companyName') }}</label>
                      <InputText v-model="companyName" @keyup.enter="onSearch" />
                    </div>
                    <div class="co-input-marketing">
                      <label for="">{{ $t('international.inputs.address') }}</label>
                      <InputText v-model="zip" @keyup.enter="onSearch"
                        :placeholder="t('marketing.companyDetails.inputs.zip')" />
                      <InputText v-model="city" @keyup.enter="onSearch" class="ml-1"
                        :placeholder="t('marketing.companyDetails.inputs.city')" />
                      <InputText v-model="street" @keyup.enter="onSearch" class="ml-1"
                        :placeholder="t('marketing.companyDetails.inputs.street')" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="co-input-marketing">
                      <label for="">{{ $t('international.inputs.companyNumber') }}</label>
                      <InputText v-model="companyNumber" @keyup.enter="onSearch" />
                    </div>
                    <div class="co-input-marketing">
                      <label for="">{{ $t('international.inputs.taxNumber') }}</label>
                      <InputText v-model="taxNumber" @keyup.enter="onSearch" />
                    </div>
                  </div>
                </div>
                <div class="search-field">
                  {{ $t('marketing.search') }}
                  <button class="button button--primary mt-5" @click="onSearch">
                    {{ $t('marketing.buttons.search') }}
                  </button>
                </div>
              </template>
            </Card>
            <Card class="p-4">
              <template #header>
                <i class="pi pi-copy" />
                <span class="title">{{ t('international.offlineTitle') }}</span>
              </template>
              <template #content>
                <form @submit="onOrder">
                  <div>
                    <div class="info-box">
                      {{ t('international.infoBox') }}
                    </div>
                    <p class="subtitle">
                      {{ t('international.companyDetails') }}
                    </p>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.country') }}</label>
                          <Dropdown v-model="country" :options="data.offline" option-label="name"
                            :placeholder="t('multiselect.placeholder')" class="w-100" option-value="key" />
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.companyName') }}</label>
                          <InputText v-model="companyName" />
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ $t('international.inputs.address') }}</label>
                          <InputText v-model="zip" :placeholder="t('marketing.companyDetails.inputs.zip')" />

                          <InputText v-model="city" aria-describedby="city-help" class="ml-1"
                            :placeholder="t('marketing.companyDetails.inputs.city')" />
                          <InputText v-model="street" class="ml-1"
                            :placeholder="t('marketing.companyDetails.inputs.street')" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.taxNumber') }}</label>
                          <InputText v-model="taxNumber" />
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.companyNumber') }}</label>
                          <InputText v-model="companyNumber" />
                        </div>
                      </div>
                    </div>
                    <div class="search-field mt-5">
                      <div v-if="offlinePrice" class="text-center mb-5">
                        {{ t('international.download.purchase.purchasePriceText') }}
                        <b>{{ offlinePrice }} EUR</b>
                      </div>
                      <Message v-for="field in Object.keys(errors)" severity="error" :closable="false">{{
                        errors[field] }}</Message>
                      <ConfirmDialog />
                      <button class="button button--primary" type="submit">
                        {{ t('international.download.purchase.purchaseButtonText') }}
                      </button>
                    </div>

                  </div>
                  <div v-if="offlineOrderResult" class="info-box mt-5">
                    {{ offlineOrderResult.message }}
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, computed } from "vue";
import { http, t, router, route, init, toast } from "@/logic";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useForm } from 'vee-validate';
import { object, string, bool } from 'yup';

init();

const initialOrder = {
  country: "",
  companyName: "",
  zip: "",
  city: "",
  street: "",
  taxNumber: "",
  companyNumber: "",
  express: false
};

const { defineField, handleSubmit, resetForm, errors } = useForm({
  initialValues: initialOrder,
  validationSchema: object({
    country: string().required(t('yupValidation.required', { value: t('international.inputs.country') })),
    companyName: string().required(t('yupValidation.required', { value: t('international.inputs.companyName') })),
    zip: string().required(t('yupValidation.required', { value: t('marketing.companyDetails.inputs.zip') })),
    city: string().required(t('yupValidation.required', { value: t('marketing.companyDetails.inputs.city') })),
    street: string().required(t('yupValidation.required', { value: t('marketing.companyDetails.inputs.street') })),
    taxNumber: string(),
    companyNumber: string(),
  })
});
const [country] = defineField('country');
const [companyName] = defineField('companyName');
const [zip] = defineField('zip');
const [city] = defineField('city');
const [street] = defineField('street');
const [taxNumber] = defineField('taxNumber');
const [companyNumber] = defineField('companyNumber');
const confirm = useConfirm();
const onlineCountry = ref("at");
const data = ref({
  online: [],
  offline: [],
});
const offlineOrderResult = ref(null);

const offlinePrice = computed(() => data.value.offline.find((c) => c.key === country.value)?.price);

function onSearch() {
  router.push({
    name: "international.results",
    query: {
      country: onlineCountry.value,
      name: companyName.value,
      zip: zip.value,
      city: city.value,
      street: street.value,
      taxNumber: taxNumber.value,
      companyNumber: companyNumber.value
    }
  });
}

const onOrder = handleSubmit(() => {
  confirm.require({
    position: "center",
    header: t("international.offlineTitle"),
    message: t("international.confirm"),
    acceptLabel: t("marketingExport.okTitle"),
    rejectLabel: t("marketingExport.cancelTitle"),
    accept: () => {
      sendOrder();
    }
  });
});

async function sendOrder() {
  try {
    offlineOrderResult.value = null;

    const response = await http.post("/api/international/offline", {
      country: country.value,
      name: companyName.value,
      zip: zip.value,
      city: city.value,
      street: street.value,
      taxNumber: taxNumber.value,
      companyNumber: companyNumber.value,
    });

    offlineOrderResult.value = response;
    resetForm();
  } catch (error) {
    toast(t('unexpectedError'), "error");
  }
}

onMounted(async () => {
  await http.fetch(data, "/api/international/country-list");
});
</script>

<style lang="scss" scoped>
.co-input-marketing {
  label {
    min-width: 12rem !important;
  }
}

.page-international {
  max-width: 120rem;
  margin: 0 auto;

  & .marketing-card {
    margin-top: 1rem;
  }

  & .subtitle {
    margin: 2rem 0 1rem;
    color: $color-font-quaternary;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  & .search-field {
    & p {
      margin: 4rem 0 2rem;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: center;
    }

    & button {
      margin: 0 auto;
    }
  }

  & .info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: $color-font-primary;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: $color-international-info-bg;

    &::before {
      margin-right: 0.5rem;
      color: $color-primary;
      font-size: 2.4rem;
      font-family: $font-icon;
      content: "\e932";
    }
  }
}

.error {
  margin-top: -11px;
  margin-left: 19rem;
  padding: 0;
  color: #e04242;
  font-size: 1.2rem;
  list-style: none;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    padding: 0;
  }
}
</style>